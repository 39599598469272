import React from 'react'

function TermsOfService() {
  return (
    <div className="bg-gray-50">
      {/* <HeaderClear showLogo={true} showRun={false} /> */}
      <div className="flex flex-col bg-white">
        <div className="mx-auto max-w-4xl px-4 py-8">
          <h1 className="mb-4 text-2xl font-bold">
            BetterCodeAI Terms of Service
          </h1>

          <div className="mb-8">
            <h2 className="mb-2 text-lg font-bold">Introduction</h2>
            <p className="text-gray-700">
              Welcome to BetterCodeAI, a web application that provides a suite
              of programming enhancement tools, such as converting code to
              different languages, writing test code, etc. Our service uses Chat
              GPT to provide you with the best possible experience. By using
              BetterCodeAI, you agree to the terms and conditions outlined in
              this document.
            </p>
          </div>

          <div className="mb-8">
            <h2 className="mb-2 text-lg font-bold">Intellectual Property</h2>
            <p className="text-gray-700">
              All content and intellectual property on BetterCodeAI is the
              exclusive property of BetterCodeAI. This includes all text,
              images, graphics, software, code, and other materials used to
              create our service. You agree not to copy, distribute, or modify
              any of the content on our website or service without our express
              written consent.
            </p>
          </div>

          <div className="mb-8">
            <h2 className="mb-2 text-lg font-bold">Disclaimer of Warranties</h2>
            <p className="text-gray-700">
              BetterCodeAI makes no warranties, express or implied, with respect
              to our service or any content provided on our website. We do not
              guarantee the accuracy, completeness, or usefulness of any
              information provided, and we disclaim all liability for any errors
              or omissions in the content. We also make no warranties that our
              service will be uninterrupted or error-free, or that any defects
              will be corrected.
            </p>
          </div>

          <div className="mb-8">
            <h2 className="mb-2 text-lg font-bold">Limitation of Liability</h2>
            <p className="text-gray-700">
              BetterCodeAI shall not be liable for any damages of any kind
              arising from the use of our service, including but not limited to
              direct, indirect, incidental, punitive, and consequential damages.
              We shall not be liable for any loss or damage caused by the
              inability to use our service, or by any errors or omissions in the
              content provided.
            </p>
          </div>

          <div className="mb-8">
            <h2 className="mb-2 text-lg font-bold">Indemnification</h2>
            <p className="text-gray-700">
              You agree to indemnify and hold harmless BetterCodeAI, our
              affiliates, and our respective officers, directors, employees, and
              agents, from any and all claims, damages, expenses, and
              liabilities (including reasonable attorneys' fees) arising from
              your use of our service, or from any breach of these Terms of
              Service.
            </p>
          </div>

          <div className="mb-8">
            <h2 className="mb-2 text-lg font-bold">Termination</h2>
            <p className="text-gray-700">
              BetterCodeAI may terminate your use of our service at any time,
              without notice or liability, for any reason whatsoever. Upon
              termination, all provisions of these Terms of Service which by
              their nature should survive termination shall survive, including,
              without limitation, ownership provisions, warranty disclaimers,
              indemnity, and limitations of liability.
            </p>
          </div>
          <div className="mb-8">
            <h2 className="mb-2 text-lg font-bold">
              Changes to These Terms of Service
            </h2>
            <p className="text-gray-700">
              We reserve the right to modify these Terms of Service at any time.
              Any changes will be posted on this page, and it is your
              responsibility to review these Terms of Service periodically to
              ensure that you are aware of any modifications. Your continued use
              of our service after any such modifications will constitute your
              acceptance of the new Terms of Service.
            </p>
          </div>

          <div>
            <h2 className="mb-2 text-lg font-bold">Contact Us</h2>
            <p className="text-gray-700">
              If you have any questions about these Terms of Service, please
              contact us at support@bettercodeai.com.
            </p>
          </div>
        </div>
      </div>

      {/* <Footer /> */}
    </div>
  )
}

export default TermsOfService
