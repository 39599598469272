import React, { useState, useEffect } from 'react'
import { useCookies } from 'react-cookie'

const CookieNotice = () => {
  const [cookies, setCookie] = useCookies(['cookie-notice'])
  const [showNotice, setShowNotice] = useState(!cookies['cookie-notice'])

  const handleAccept = () => {
    setCookie('cookie-notice', true, { maxAge: 604800 })
    setShowNotice(false)
  }

  useEffect(() => {
    // console.log('Cookies', cookies)
  }, [])

  return showNotice ? (
    <div className="fixed bottom-0 left-0 w-full bg-blue-500 p-4 text-white">
      <p className="text-sm md:text-base">
        We use cookies to personalize content and provide social media features
        and to analyze our traffic. We also share information about your use of
        our site with our social media, advertising and analytics partners who
        may combine it with other information that you’ve provided to them or
        that they’ve collected from your use of their services.
      </p>

      <button
        onClick={handleAccept}
        className="mt-4 rounded bg-white py-2 px-4 font-bold text-black hover:bg-black hover:text-white"
      >
        Accept all cookies
      </button>
    </div>
  ) : null
}

export default CookieNotice
