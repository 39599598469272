import React, { useState } from 'react'
import APIContext, { QuestionAndResponse } from './APIContext'
import { EditorCode } from './EditorContext'
import { cloneDeep } from 'lodash'
import { InitialQuestion } from '../home/ChatScroll'
import { createInitialQuestion } from '../utils/InitialQuestionUtil'

export interface Session {
  questionAndResponses: QuestionAndResponse[]
  editorValue: EditorCode
  initialQuestion: InitialQuestion
}

const newSession = {
  questionAndResponses: [],
  editorValue: {
    original: '',
    modified: '',
    ai: '',
    language: '',
  },
  initialQuestion: {
    text: '',
    recommendations: [],
  },
}

export const CurrentSessionContext = React.createContext<Session[]>(null)
export const SetCurrentSessionContext = React.createContext(null)
export const CurrentSessionIndex = React.createContext(0)
export const SetCurrentSessionIndex = React.createContext(null)

const SessionContext = () => {
  const [sessions, setSessions] = useState([newSession])
  const [currentSessionIndex, setCurrentSessionIndex] = useState(0)

  function addSession(
    questionAndResponses, // current
    editorValue, // current
    currentIndex, // current
    newIndex, // new
    isNew, // if just click approved
    initialQuestion // current
  ) {
    // if you just approved changes, you are making a new session
    if (isNew) {
      const deepCopyQA = cloneDeep(questionAndResponses)
      const deepCopyEV = cloneDeep(editorValue)
      const deepCopyIQ = cloneDeep(initialQuestion)

      // saving state and pushing on a sessionQueue
      const newSession = {
        questionAndResponses: deepCopyQA,
        editorValue: deepCopyEV,
        initialQuestion: deepCopyIQ,
      }

      setSessions((prev) => {
        const updatedItems = [
          {
            ...prev[0],
            initialQuestion: {
              // overwrite current initial question
              text: `Nice! Let's keep making this code better!`,
              recommendations: createInitialQuestion(questionAndResponses),
            },
            editorValue: {
              // overwrite starting code
              original: editorValue.modified,
              modified: '',
              language: '',
              ai: '',
            },
          },
          ...prev.slice(1),
          newSession,
        ]
        return updatedItems
      })

      setCurrentSessionIndex(newIndex)
    } else {
      // swapping between existing sessions
      const deepCopyQA = cloneDeep(questionAndResponses)
      const deepCopyEV = cloneDeep(editorValue)
      const deepCopyIQ = cloneDeep(initialQuestion)

      // saving state and pushing on a sessionQueue
      const newSession = {
        questionAndResponses: deepCopyQA,
        editorValue: deepCopyEV,
        initialQuestion: deepCopyIQ,
      }

      setSessions((prev) => {
        const updatedItems = prev.map((item, index) => {
          if (index === currentIndex) {
            // update the item at the current index with a snapshot of where we currently are
            return newSession
          } else {
            // keep the item as-is
            return item
          }
        })
        return updatedItems
      })
      setCurrentSessionIndex(newIndex)
    }
  }

  return (
    <CurrentSessionContext.Provider value={sessions}>
      <SetCurrentSessionContext.Provider value={addSession}>
        <CurrentSessionIndex.Provider value={currentSessionIndex}>
          <SetCurrentSessionIndex.Provider value={setCurrentSessionIndex}>
            <APIContext />
          </SetCurrentSessionIndex.Provider>
        </CurrentSessionIndex.Provider>
      </SetCurrentSessionContext.Provider>
    </CurrentSessionContext.Provider>
  )
}
export default SessionContext
