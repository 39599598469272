import React from 'react'
import BetterCode from '../editor/BetterCodeEditor'
import awsconfig from '../../aws-exports'
import { Auth } from 'aws-amplify'
import { Buffer } from 'buffer'
global.Buffer = Buffer
import aws4 from 'aws4'
import HistoryComponent from './HistoryComponent'
import ChatScroll from './ChatScroll'
import SendMessage from './SendMessage'

const getApiBaseUrl = () => {
  const api = awsconfig.aws_cloud_logic_custom.find(
    (item) => item.name === 'nodeapi'
  )
  return api.endpoint
}

async function signRequest(request) {
  try {
    const credentials = await Auth.currentCredentials()
    const accessInfo = {
      accessKeyId: credentials.accessKeyId,
      secretAccessKey: credentials.secretAccessKey,
      sessionToken: credentials.sessionToken,
    }
    const parsedUrl = new URL(request.url)
    request.headers['Host'] = parsedUrl.host // Add the 'Host' header
    const signedRequest = aws4.sign(request, accessInfo)
    return signedRequest
  } catch (error) {
    console.log('No user signed in or failed to get credentials', error)
    // You can return the original request for guest users.
    return request
  }
}

function generateGuestToken() {
  // Replace this with your own guest token generation logic, if necessary.
  return 'guest_id=' + Date.now()
}

async function getAuthHeader() {
  try {
    const session = await Auth.currentSession()
    const idToken = session.getIdToken().getJwtToken()
    return { Authorization: `Bearer ${idToken}` }
  } catch (error) {
    console.log('No user signed in', error)
    const guestToken = generateGuestToken()
    return { Authorization: `Bearer ${guestToken}` }
  }
}

const Main = () => {
  return (
    <div className="relative flex h-full w-full overflow-hidden">
      <HistoryComponent />
      <div className="flex h-full max-w-full flex-1 flex-col">
        <div className="flex h-full flex-col lg:flex-row">
          <div className="flex h-full flex-1 flex-col dark:bg-gray-800">
            <main className="transition-width relative flex h-full w-full flex-1 flex-col items-stretch overflow-hidden">
              <ChatScroll />
              <SendMessage />
            </main>
          </div>
          <div className="hidden flex-1 lg:flex">
            <BetterCode />
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(Main)
