import React, { useContext, useEffect } from 'react'
import { QuestionsAndResponses } from '../contexts/APIContext'
import ChatItemWrapper from './ChatItemWrapper'

const UserAndAIChat = ({ handleClick }) => {
  const questionsAndResponses = useContext(QuestionsAndResponses)

  useEffect(() => {
    handleClick()
  })
  return (
    <>
      {questionsAndResponses.map((questionAndResponse, index) => (
        <ChatItemWrapper
          key={index}
          questionAndResponse={questionAndResponse}
          index={index}
        />
      ))}
    </>
  )
}
export default UserAndAIChat
