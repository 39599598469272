import React, { useContext, useEffect, useRef, useState } from 'react'
import {
  AuthorizationContext,
  SubscriptionContext,
} from '../contexts/AuthContext'
import { API } from 'aws-amplify'
import { Subscription } from '../../Models/Subscription'
import PaymentButton from '../payment/PaymentButton'

const AccountModal = ({ isOpen, closeModal }) => {
  const user: any = useContext(AuthorizationContext)
  const subscription: Subscription = useContext(SubscriptionContext)

  const [isLoadingUsageData, setIsLoadingUsageData] = useState(true)
  const [dailyLimit, setDailyLimit] = useState(25)
  const [limitUsed, setLimitUsed] = useState(0)

  function calculatePercentage(num, total) {
    const percentage = (num / total) * 100
    return percentage.toFixed(0) + '%'
  }

  useEffect(() => {
    if (subscription.subscriptionStatus === 'active') {
      setDailyLimit(1000)
    }
  }, [subscription])

  useEffect(() => {
    const getUsageData = async () => {
      try {
        if (isLoadingUsageData) {
          let myUsage = await API.get('nodeapi', `/usage/me`, {})
          console.log('GET /usage/me', myUsage)

          // TODO: Why does this work, but not setuser?
          //user["email"] = currentUser.attributes.email;
          if (myUsage && Object.keys(myUsage).length) {
            setLimitUsed(myUsage.dailyRequests)
          }
        }
      } catch (e) {
        console.error('Failed to get usage', e)
      } finally {
        setIsLoadingUsageData(false)
      }
    }
    if (isOpen) {
      getUsageData()
    }
  }, [isOpen])

  const modalContentRef = useRef(null)

  const handleClickOutside = (event) => {
    if (
      modalContentRef.current &&
      !modalContentRef.current.contains(event.target)
    ) {
      closeModal()
    }
  }

  if (!isOpen) {
    return null
  }

  return (
    <>
      <div className="fixed inset-0 z-40 bg-black opacity-50"></div>
      <div
        onClick={handleClickOutside}
        className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden  outline-none focus:outline-none"
      >
        <div className="relative mx-auto w-auto w-6/12 lg:w-3/12">
          <div
            ref={modalContentRef}
            className="relative flex w-full flex-col rounded-lg border-0 bg-white shadow-lg outline-none focus:outline-none"
          >
            <button
              className="absolute top-0 right-0 border-0 bg-transparent p-4 text-xl font-semibold leading-none text-black text-black opacity-50 outline-none focus:outline-none"
              onClick={closeModal}
            >
              ×
            </button>
            <div>
              <div className="overflow-x-hidden">
                <div>
                  <div className="flex flex-1 flex-col">
                    <main>
                      <div className="pt-0 pb-6">
                        <div className="mx-auto mt-8 px-4 sm:px-6 md:px-8">
                          <div className="mt-6">
                            <p className="text-base font-bold text-gray-900">
                              Daily request limit
                            </p>
                            <p className="mt-1 text-sm font-medium text-gray-500">
                              {limitUsed} / {dailyLimit}{' '}
                              <span className="text-green-500">
                                ({calculatePercentage(limitUsed, dailyLimit)})
                              </span>
                            </p>
                          </div>

                          <div className="mt-6">
                            <p className="text-base font-bold text-gray-900">
                              Email
                            </p>
                            <p className="mt-1 text-sm font-light text-gray-500">
                              {user.email || 'no account'}
                            </p>
                          </div>

                          <div className="mt-6">
                            <p className="text-base font-bold text-gray-900">
                              Plan type
                            </p>
                            <p className="mt-1 text-sm font-light text-gray-500">
                              {subscription.hasSubscription
                                ? 'Plus'
                                : 'free plan'}
                            </p>
                          </div>

                          <div className="mt-6">
                            <p className="text-base font-bold text-gray-900">
                              Subscription
                            </p>
                            <p className="mt-1 text-sm font-light text-gray-500">
                              {subscription.subscriptionStatus ||
                                'no active subscription'}
                            </p>
                          </div>

                          {subscription.hasSubscription ? (
                            <div className="mt-6">
                              <p className="mt-1 text-sm font-medium text-green-500">
                                <a href="https://billing.stripe.com/p/login/test_7sI6q1eOK3PH6accMM">
                                  Manage your subscription
                                </a>
                              </p>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </main>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AccountModal
