export interface Subscription {
  isPaymentLoading: boolean
  subscriptionStatus: string
  userId: string
  subscriptionId: string
  plan: string
  createdAt: string
  updatedAt: string
  stripeEmail: string
  hasSubscription: boolean
}

export const defaultSubscription: Subscription = {
  isPaymentLoading: true,
  userId: null,
  subscriptionStatus: '',
  subscriptionId: null,
  plan: null,
  createdAt: '',
  updatedAt: '',
  stripeEmail: '',
  hasSubscription: false,
}
