export interface Cognito {
  identityId: string
  accessKeyId: string
  secretAccessKey: string
  sessionToken: string
}

export const defaultCognito: Cognito = {
  identityId: null,
  accessKeyId: null,
  secretAccessKey: null,
  sessionToken: null,
}
