import React, { useContext, useEffect, useRef, useState } from 'react'
import {
  CurrentSessionContext,
  CurrentSessionIndex,
} from '../contexts/SessionContext'
import ChatItemAI from './ChatItemAI'
import UserAndAIChat from './UserAndAIChat'

export interface InitialQuestion {
  text: String
  recommendations: String[]
}

export const initialQuestionText = `Hello! Paste code in the editor then ask me anything!

I will improve your code, show you the diff, and give you further suggestions on how to take your code to the next level!`

export const initialRecommendations = [
  'Create Node API',
  'Write code to reverse string',
  'Write Sample Javascript Code',
]

const ChatScroll = () => {
  const currentSessionContext = useContext(CurrentSessionContext)
  const currentSessionIndex = useContext(CurrentSessionIndex)
  const scrollToRef = useRef(null)

  const handleClick = () => {
    const scrollElem = scrollToRef.current
    scrollElem.scrollIntoView({ behavior: 'auto', block: 'end', inline: 'end' })
  }

  const [maxHeight, setMaxHeight] = useState(0)

  useEffect(() => {
    const screenHeight = window.innerHeight
    console.log
    const newMaxHeight = screenHeight - 150
    setMaxHeight(newMaxHeight)
  }, [])

  return (
    <div
      className="flex-1 overflow-hidden"
      style={{ maxHeight: `${maxHeight}px` }}
    >
      {/* <div className="flex-1 overflow-hidden"> */}
      <div className="relative h-full dark:bg-gray-800">
        <div className="h-full w-full overflow-y-auto">
          <div className="flex flex-col items-center text-sm dark:bg-gray-800">
            {/** HEADER */}
            <div className="flex w-full items-center justify-center gap-1 border-b border-black/10 bg-gray-50 p-3 text-gray-500 dark:border-gray-900/50 dark:bg-gray-700 dark:text-gray-300">
              Better Code AI
            </div>
            <ChatItemAI
              buffer={
                currentSessionContext[currentSessionIndex].initialQuestion.text
                  .length
                  ? currentSessionContext[currentSessionIndex].initialQuestion
                      .text
                  : initialQuestionText
              }
              isComplete={true}
              isInitial={true}
              calcdRecommendations={
                currentSessionContext[currentSessionIndex].initialQuestion
                  .recommendations.length
                  ? currentSessionContext[currentSessionIndex].initialQuestion
                      .recommendations
                  : initialRecommendations
              }
            />
            <UserAndAIChat handleClick={handleClick} />
            {/* <div id="scrollToId" className="flex"></div> */}
            <div ref={scrollToRef} className="h-1 w-full flex-shrink-0"></div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ChatScroll
