import './App.css'
import React, { useEffect } from 'react'
import ReactGA from 'react-ga4'
import {
  createBrowserRouter,
  RouterProvider,
  useLocation,
} from 'react-router-dom'

import TermsOfService from './components/legal/TermsOfService'
import UserAgreement from './components/legal/UserAgreement'
import PrivacyPolicy from './components/legal/PrivacyPolicy'
import Error404 from './components/errors/Error404'
import BetterCode from './components/editor/BetterCodeEditor'
import Main from './components/home/Main'
import APIContext from './components/contexts/APIContext'
import SessionContext from './components/contexts/SessionContext'

function App() {
  // TODO: Add within the context of the Router component
  // const location = useLocation();

  // useEffect(() => {
  //   // track pageview with gtag / react-ga / react-ga4, for example:
  //   ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
  //   //ReactGA.send({ hitType: "pageview", page: location.pathname + location.search, title: "Custom Title" });
  // }, [location]);

  const router = createBrowserRouter([
    {
      path: '/',
      element: <SessionContext />,
      errorElement: <Error404 />,
    },
    {
      path: '/terms-of-service',
      element: <TermsOfService />,
      errorElement: <Error404 />,
    },
    {
      path: '/user-agreement',
      element: <UserAgreement />,
      errorElement: <Error404 />,
    },
    {
      path: '/privacy-policy',
      element: <PrivacyPolicy />,
      errorElement: <Error404 />,
    },
  ])

  return <RouterProvider router={router} />
}

export default App
