import { diffLines } from 'diff'

export const getDifferences = (original, modified) => {
  const diffs = diffLines(original, modified);
  let resultWithoutDiffs = '';
  let diffTypeArr = [];

  diffs.forEach((part) => {
    const lines = part.value.split('\n');
    lines.pop(); // Remove the last empty line

    lines.forEach((line) => {
      if (part.added) {
        resultWithoutDiffs += `${line}\n`;
        diffTypeArr.push('added');
      } else if (part.removed) {
        resultWithoutDiffs += `${line}\n`;
        diffTypeArr.push('removed');
      } else {
        resultWithoutDiffs += `${line}\n`;
        diffTypeArr.push('unchanged');
      }
    });
  });

  return { resultWithoutDiffs, diffTypeArr };
};

export const getLanguageIdentifier = (md) => {
  const regex = /```([a-zA-Z]*)/g
  const match = regex.exec(md)

  if (match && match[1]) {
    return match[1]
  } else {
    return null
  }
}

export const removeLanguageIdentifier = (md) => {
  return md.replace(/(```)([a-zA-Z]*)/g, '```')
}
  
export const calcAPIResponse = (chunks) => {
  return chunks.map((item) => item.content).join('')
}

export const calcAPIResponseFromMainArray = (questionsAndResponses) => {
  return questionsAndResponses.length
    ? calcAPIResponse(questionsAndResponses[questionsAndResponses.length - 1].chunks)
    : ''
}

export const calcIsComplete = (
  chunks,
  isStreamingComplete,
  completeStreamIndex
) => {
  return isStreamingComplete && chunks.length + 1 >= completeStreamIndex
}

export const calcIsComplete1Param = (questionsAndResponses) => {
  return questionsAndResponses.length
    ? calcIsComplete(questionsAndResponses[questionsAndResponses.length - 1].chunks, questionsAndResponses[questionsAndResponses.length - 1].isStreamingComplete,
      questionsAndResponses[questionsAndResponses.length - 1]
            .completeStreamIndex)
    : false
}