import React from 'react'

const ChatItem = ({ message }) => {
  return (
    <div className="group w-full border-b border-black/10 text-gray-800 dark:border-gray-900/50 dark:bg-gray-800 dark:text-gray-100">
      <div className="m-auto flex gap-4 p-4 text-base md:max-w-2xl md:gap-6 md:py-6 lg:max-w-xl lg:px-0 xl:max-w-3xl">
        <div className="relative flex w-[30px] flex-col items-end">
          <div className="relative flex">
            <span className="box-sizing: border-box; display: inline-block; overflow: hidden; background-image: none; opacity: 1; border: 0px; margin: 0px; padding: 0px; position: relative; max-width: 100%;">
              <span className="box-sizing: border-box; display: block; background-image: none; opacity: 1; border: 0px; margin: 0px; padding: 0px; max-width: 100%;">
                <img
                  alt="Rob Zuazua"
                  srcSet="/_next/image?url=https%3A%2F%2Flh3.googleusercontent.com%2Fa%2FAGNmyxYx31HgXCBg9v8cGEWV_7_SN7x1npSlCs5ANDy_%3Ds96-c&w=32&q=75 1x, /_next/image?url=https%3A%2F%2Flh3.googleusercontent.com%2Fa%2FAGNmyxYx31HgXCBg9v8cGEWV_7_SN7x1npSlCs5ANDy_%3Ds96-c&w=64&q=75 2x"
                  src="/_next/image?url=https%3A%2F%2Flh3.googleusercontent.com%2Fa%2FAGNmyxYx31HgXCBg9v8cGEWV_7_SN7x1npSlCs5ANDy_%3Ds96-c&w=64&q=75"
                  decoding="async"
                  data-nimg="intrinsic"
                  className="rounded-sm"
                  style={{
                    position: 'absolute',
                    inset: '0px',
                    boxSizing: 'border-box',
                    padding: '0px',
                    border: 'none',
                    margin: 'auto',
                    display: 'block',
                    width: '0px',
                    height: '0px',
                    minWidth: '100%',
                    maxWidth: '100%',
                    minHeight: '100%',
                    maxHeight: '100%',
                  }}
                />{' '}
              </span>
            </span>
          </div>
        </div>
        <div className="relative flex w-[calc(100%-50px)] flex-col gap-1 md:gap-3 lg:w-[calc(100%-115px)]">
          <div className="flex flex-grow flex-col gap-3">
            <div className="flex min-h-[20px] flex-col items-start gap-4 whitespace-pre-wrap">
              {message}
            </div>
          </div>
          <div className="flex justify-between lg:block"></div>
        </div>
      </div>
    </div>
  )
}

export default ChatItem
