import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import reportWebVitals from './reportWebVitals'
import ReactGA from 'react-ga4'
import 'stream-http'
import AuthContext from './components/contexts/AuthContext'
import CookieNotice from './components/legal/CookieNotice'
ReactGA.initialize('G-JZNK3CDJ97', {})

// trackingID -- G-JZNK3CDJ97
// propertyID -- 357155364
// Google tag manager -- GTM-5WLDX9R

// Send pageview with a custom path
// ReactGA.send({ hitType: "pageview", page: "/my-path", title: "Custom Title" });

if (process.env.USER_BRANCH === 'prod') {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <AuthContext />
    <CookieNotice />
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
