import React, { useContext, useRef, useState } from 'react'
import {
  AuthorizationContext,
  SubscriptionContext,
} from '../contexts/AuthContext'
import { User } from '../../Models/User'
import AccountModal from '../account/AccountModal'
import VersionItem from './VersionItem'
import { Auth } from 'aws-amplify'
import {
  CurrentSessionContext,
  CurrentSessionIndex,
  Session,
  SetCurrentSessionIndex,
} from '../contexts/SessionContext'
import { EditorSelector, EditorSetterContext } from '../contexts/EditorContext'
import { SetQuestionsAndResponsesContext } from '../contexts/APIContext'
import PaymentButton from '../payment/PaymentButton'
import PaymentListItem from '../payment/PaymentListItem'
import { Subscription } from '../../Models/Subscription'

const HistoryComponent = () => {
  const user: User = useContext(AuthorizationContext)
  const subscription: Subscription = useContext(SubscriptionContext)
  const [isAccountModalOpen, setIsAccountModalOpen] = useState(false)
  const setEditorValue = useContext(EditorSetterContext)
  const currentSessionContext: Session[] = useContext(CurrentSessionContext)
  const currentSessionIndex = useContext(CurrentSessionIndex)
  const setCurrentSessionIndex = useContext(SetCurrentSessionIndex)
  const setQuestionsAndResponses = useContext(SetQuestionsAndResponsesContext)

  const openAccountModal = () => {
    setIsAccountModalOpen(true)
  }

  const closeAccountModal = () => {
    setIsAccountModalOpen(false)
  }

  const handleReset = () => {
    if (currentSessionIndex !== 0) {
      setCurrentSessionIndex(0)
    } else {
      setEditorValue('', EditorSelector.BOTH)
      setQuestionsAndResponses([])
    }
  }

  return (
    <div className="dark hidden bg-gray-900 md:flex md:w-[260px] md:flex-col">
      <div className="flex h-full min-h-0 flex-col ">
        <div className="scrollbar-trigger flex h-full w-full flex-1 items-start border-white/20">
          <nav className="flex h-full flex-1 flex-col space-y-1 p-2">
            <a
              onClick={handleReset}
              className="mb-2 flex flex-shrink-0 cursor-pointer items-center gap-3 rounded-md border border-white/20 py-3 px-3 text-sm text-white transition-colors duration-200 hover:bg-gray-500/10"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="h-5 w-5"
              >
                <path
                  fillRule="evenodd"
                  d="M15.312 11.424a5.5 5.5 0 01-9.201 2.466l-.312-.311h2.433a.75.75 0 000-1.5H3.989a.75.75 0 00-.75.75v4.242a.75.75 0 001.5 0v-2.43l.31.31a7 7 0 0011.712-3.138.75.75 0 00-1.449-.39zm1.23-3.723a.75.75 0 00.219-.53V2.929a.75.75 0 00-1.5 0V5.36l-.31-.31A7 7 0 003.239 8.188a.75.75 0 101.448.389A5.5 5.5 0 0113.89 6.11l.311.31h-2.432a.75.75 0 000 1.5h4.243a.75.75 0 00.53-.219z"
                  clipRule="evenodd"
                />
              </svg>
              Reset Current Version
            </a>
            <div className="flex-1 flex-col overflow-y-auto border-b border-white/20">
              <div className="flex flex-col gap-2 text-sm text-gray-100">
                {/* <a className={AclassNames} onClick={handleClickCurrent}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="h-5 w-5"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.25 2A2.25 2.25 0 002 4.25v11.5A2.25 2.25 0 004.25 18h11.5A2.25 2.25 0 0018 15.75V4.25A2.25 2.25 0 0015.75 2H4.25zm4.03 6.28a.75.75 0 00-1.06-1.06L4.97 9.47a.75.75 0 000 1.06l2.25 2.25a.75.75 0 001.06-1.06L6.56 10l1.72-1.72zm4.5-1.06a.75.75 0 10-1.06 1.06L13.44 10l-1.72 1.72a.75.75 0 101.06 1.06l2.25-2.25a.75.75 0 000-1.06l-2.25-2.25z"
                      clipRule="evenodd"
                    />
                  </svg>

                  <div className="relative max-h-5 flex-1 overflow-hidden text-ellipsis break-all">
                    Current
                    <div className="absolute inset-y-0 right-0 z-10 w-8 from-gray-800"></div>
                  </div>
                </a> */}
                {currentSessionContext.map((session, index) => (
                  <VersionItem
                    version={
                      index === 0
                        ? 'Current'
                        : session.questionAndResponses.length
                        ? session.questionAndResponses[
                            session.questionAndResponses.length - 1
                          ].userQuestion
                        : `Version ${index}`
                    }
                    session={session}
                    key={index}
                    isHighlight={index === currentSessionIndex}
                    index={index}
                  />
                ))}
                {/* <VersionItem version={'Version 4'} />
                <VersionItem version={'Version 3'} />
                <VersionItem version={'Version 2'} />
                <VersionItem version={'Version 1'} /> */}
              </div>
            </div>
            {subscription.isPaymentLoading ||
            subscription.subscriptionStatus === 'active' ? (
              <></>
            ) : (
              <PaymentListItem />
            )}

            {user.isLoading ? (
              <></>
            ) : user.userId ? (
              <>
                <button
                  onClick={openAccountModal}
                  className="flex cursor-pointer items-center gap-3 rounded-md py-3 px-3 text-sm text-white transition-colors duration-200 hover:bg-gray-500/10"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="h-5 w-5"
                  >
                    <path d="M10 8a3 3 0 100-6 3 3 0 000 6zM3.465 14.493a1.23 1.23 0 00.41 1.412A9.957 9.957 0 0010 18c2.31 0 4.438-.784 6.131-2.1.43-.333.604-.903.408-1.41a7.002 7.002 0 00-13.074.003z" />
                  </svg>
                  My account
                </button>
                <a
                  onClick={() => Auth.signOut()}
                  className="flex cursor-pointer items-center gap-3 rounded-md py-3 px-3 text-sm text-white transition-colors duration-200 hover:bg-gray-500/10"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="h-5 w-5"
                  >
                    <path
                      fillRule="evenodd"
                      d="M3 4.25A2.25 2.25 0 015.25 2h5.5A2.25 2.25 0 0113 4.25v2a.75.75 0 01-1.5 0v-2a.75.75 0 00-.75-.75h-5.5a.75.75 0 00-.75.75v11.5c0 .414.336.75.75.75h5.5a.75.75 0 00.75-.75v-2a.75.75 0 011.5 0v2A2.25 2.25 0 0110.75 18h-5.5A2.25 2.25 0 013 15.75V4.25z"
                      clipRule="evenodd"
                    />
                    <path
                      fillRule="evenodd"
                      d="M19 10a.75.75 0 00-.75-.75H8.704l1.048-.943a.75.75 0 10-1.004-1.114l-2.5 2.25a.75.75 0 000 1.114l2.5 2.25a.75.75 0 101.004-1.114l-1.048-.943h9.546A.75.75 0 0019 10z"
                      clipRule="evenodd"
                    />
                  </svg>
                  Log out
                </a>
              </>
            ) : (
              <a
                onClick={() => Auth.federatedSignIn()}
                className="flex cursor-pointer items-center gap-3 rounded-md py-3 px-3 text-sm text-white transition-colors duration-200 hover:bg-gray-500/10"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="h-5 w-5"
                >
                  <path
                    fillRule="evenodd"
                    d="M3 4.25A2.25 2.25 0 015.25 2h5.5A2.25 2.25 0 0113 4.25v2a.75.75 0 01-1.5 0v-2a.75.75 0 00-.75-.75h-5.5a.75.75 0 00-.75.75v11.5c0 .414.336.75.75.75h5.5a.75.75 0 00.75-.75v-2a.75.75 0 011.5 0v2A2.25 2.25 0 0110.75 18h-5.5A2.25 2.25 0 013 15.75V4.25z"
                    clipRule="evenodd"
                  />
                  <path
                    fillRule="evenodd"
                    d="M19 10a.75.75 0 00-.75-.75H8.704l1.048-.943a.75.75 0 10-1.004-1.114l-2.5 2.25a.75.75 0 000 1.114l2.5 2.25a.75.75 0 101.004-1.114l-1.048-.943h9.546A.75.75 0 0019 10z"
                    clipRule="evenodd"
                  />
                </svg>
                Sign In
              </a>
            )}
          </nav>
        </div>
      </div>

      <div className="flex">
        <AccountModal
          isOpen={isAccountModalOpen}
          closeModal={closeAccountModal}
        />
      </div>
    </div>
  )
}
export default HistoryComponent
