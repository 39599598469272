import React, { useContext, useState, useRef, useEffect } from 'react'

import {
  AuthorizationContext,
  SubscriptionContext,
} from '../contexts/AuthContext'

const PaymentListItem = (props) => {
  const user: any = useContext(AuthorizationContext)
  const subscription: any = useContext(SubscriptionContext)
  const [showModal, setShowModal] = useState(false)
  const clientReferenceId =
    user && user.userId ? user.userId.split(':')[1] : 'please_sign_in'

  const contentRef = useRef()

  const closeModal = () => {
    setShowModal(false)
  }

  useOnClickOutside(contentRef, () => {
    closeModal()
  })

  function useOnClickOutside(ref, handler) {
    useEffect(() => {
      const listener = (event) => {
        if (!ref.current || ref.current.contains(event.target)) {
          return
        }
        handler(event)
      }

      document.addEventListener('mousedown', listener)
      document.addEventListener('touchstart', listener)

      return () => {
        document.removeEventListener('mousedown', listener)
        document.removeEventListener('touchstart', listener)
      }
    }, [ref, handler])
  }

  return (
    <div>
      {Object.keys(user).length !== 0 ? (
        <div>
          <a
            onClick={() => setShowModal(true)}
            className="flex cursor-pointer items-center gap-3 rounded-md py-3 px-3 text-sm text-white transition-colors duration-200 hover:bg-gray-500/10"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="h-5 w-5"
            >
              <path
                fillRule="evenodd"
                d="M10 2a.75.75 0 01.75.75v7.5a.75.75 0 01-1.5 0v-7.5A.75.75 0 0110 2zM5.404 4.343a.75.75 0 010 1.06 6.5 6.5 0 109.192 0 .75.75 0 111.06-1.06 8 8 0 11-11.313 0 .75.75 0 011.06 0z"
                clipRule="evenodd"
              />
            </svg>
            Upgrade
          </a>

          {showModal ? (
            <>
              <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden outline-none focus:outline-none">
                <div className="relative relative mx-auto flex h-full w-full w-auto items-center justify-center px-4 py-5 sm:p-6">
                  <div
                    ref={contentRef}
                    className="max-w-sm rounded-xl bg-white shadow-lg"
                  >
                    <div className="px-4 py-5 sm:p-6">
                      <div className="text-center">
                        <p className="mt-5 mb-7 text-xl font-bold text-gray-900">
                          Better Code AI{' '}
                          <span className="titleShadow ml-1 text-green-500">
                            PLUS
                          </span>
                          <hr className="solid"></hr>
                        </p>
                        <p className="mt-3 text-sm font-medium text-gray-500">
                          <div className="flex flex-row items-center justify-start gap-2 pb-4 text-sm">
                            <svg
                              stroke="currentColor"
                              fill="none"
                              strokeWidth={2}
                              viewBox="0 0 24 24"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="h-4 h-5 w-4 w-5 text-green-700"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
                              <polyline points="22 4 12 14.01 9 11.01" />
                            </svg>
                            <span>Break free of character limit</span>
                          </div>
                          <div className="flex flex-row items-center justify-start gap-2 pb-4 text-sm">
                            <svg
                              stroke="currentColor"
                              fill="none"
                              strokeWidth={2}
                              viewBox="0 0 24 24"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="h-4 h-5 w-4 w-5 text-green-700"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
                              <polyline points="22 4 12 14.01 9 11.01" />
                            </svg>
                            <span>Enhanced AI precision</span>
                          </div>
                          <div className="flex flex-row items-center justify-start gap-2 text-sm sm:pb-1">
                            <svg
                              stroke="currentColor"
                              fill="none"
                              strokeWidth={2}
                              viewBox="0 0 24 24"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="h-4 h-5 w-4 w-5 text-green-700"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
                              <polyline points="22 4 12 14.01 9 11.01" />
                            </svg>
                            <span>Gain access to all features</span>
                          </div>
                        </p>
                        <div className="mt-8">
                          {/* <button
                            type="button"
                            className="inline-flex items-center justify-center px-6 py-3 text-sm font-semibold leading-5 text-indigo-600 transition-all duration-200 bg-indigo-100 border border-transparent rounded-md hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600 hover:bg-indigo-600"
                          >
                            Ok, Thanks!
                          </button> */}

                          {subscription.status == 'active' ? (
                            <button
                              className="btn btn-primary relative cursor-not-allowed border-none bg-gray-200 py-3 font-semibold opacity-50 hover:bg-gray-200"
                              disabled
                            >
                              <div className="flex w-full items-center justify-center gap-2">
                                <span className="inline-block text-gray-700">
                                  Your current plan
                                </span>
                              </div>
                            </button>
                          ) : (
                            <button className="btn btn-primary relative rounded rounded-md border-none bg-green-500 py-2 px-6 font-semibold hover:bg-green-700">
                              <div className="flex w-full items-center justify-center gap-2">
                                {' '}
                                <a
                                  className="p-1"
                                  href={
                                    process.env.REACT_APP_STRIPE_URL +
                                    `?client_reference_id=${clientReferenceId}`
                                  }
                                >
                                  <span className="inline-block text-base text-white">
                                    Purchase
                                  </span>
                                  <span className="ml-5 text-sm text-gray-300">
                                    USD $20/mo
                                  </span>
                                </a>
                              </div>
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-auto my-6 mx-auto max-w-3xl px-4 py-5 sm:p-6">
                  <div
                    ref={contentRef}
                    className="min-w-[275px] border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
                  >
                    <div className="flex w-full flex-row justify-between border-b py-3 px-4 dark:border-gray-700">
                      <span className="text-base font-semibold sm:text-base">
                        BetterCodeAI Plus
                      </span>
                      <button
                        onClick={() => setShowModal(false)}
                        className="text-gray-700 opacity-50 transition hover:opacity-75 dark:text-white"
                      >
                        <svg
                          stroke="currentColor"
                          fill="none"
                          strokeWidth={2}
                          viewBox="0 0 24 24"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="h-6 w-6"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <line x1={18} y1={6} x2={6} y2={18} />
                          <line x1={6} y1={6} x2={18} y2={18} />
                        </svg>
                      </button>
                    </div>

                    <div className="pt-1 px-4 pb-4 flex flex-col gap-3 bg-white z-20 relative dark:bg-gray-900 rounded-xl">
                      <div className="text-l font-semibold justify-between items-center flex flex-col"></div>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
            </>
          ) : null}
        </div>
      ) : (
        <div></div>
      )}
    </div>
  )
}
export default PaymentListItem
