import React, { useContext, useMemo } from 'react'
import {
  CurrentSessionContext,
  CurrentSessionIndex,
} from '../contexts/SessionContext'
import { calcAPIResponse, calcIsComplete } from '../editor/EditorUtils'
import ChatItem from './ChatItem'
import ChatItemAI from './ChatItemAI'
import { initialRecommendations } from './ChatScroll'

const ChatItemWrapper = ({ questionAndResponse, index }) => {
  const currentSessionContext = useContext(CurrentSessionContext)
  const currentSessionIndex = useContext(CurrentSessionIndex)

  const MemoizedChatItem = useMemo(
    () => <ChatItem message={questionAndResponse.userQuestion} />,
    [questionAndResponse.userQuestion]
  )
  const MemoizedChatItemAI = useMemo(
    () => (
      <ChatItemAI
        buffer={calcAPIResponse(questionAndResponse.chunks)}
        isInitial={false}
        isComplete={calcIsComplete(
          questionAndResponse.chunks,
          questionAndResponse.isStreamingComplete,
          questionAndResponse.completeStreamIndex
        )}
        calcdRecommendations={
          currentSessionContext[currentSessionIndex].initialQuestion
            .recommendations.length
            ? currentSessionContext[currentSessionIndex].initialQuestion
                .recommendations
            : initialRecommendations
        }
      />
    ),
    [questionAndResponse]
  )

  return (
    <React.Fragment key={index}>
      {MemoizedChatItem}
      {MemoizedChatItemAI}
    </React.Fragment>
  )
}

export default ChatItemWrapper
