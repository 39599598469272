import React, { useState, useContext, useEffect } from 'react'
import { AuthorizationContext } from './AuthContext'
import { User } from '../../Models/User'
import EditorContext from './EditorContext'
import { CurrentSessionContext, CurrentSessionIndex } from './SessionContext'
import { CognitoContext } from '../contexts/AuthContext'

export const CallBackendContext = React.createContext(null)
export const QuestionsAndResponses = React.createContext<QuestionAndResponse[]>(
  []
)

export const SetQuestionsAndResponsesContext = React.createContext(null)

export interface QuestionAndResponse {
  userQuestion: string
  chunks: any[]
  isStreamingComplete: boolean
  completeStreamIndex: number
}

const APIContext = () => {
  const user: User = useContext(AuthorizationContext)
  const cognitoContext: any = useContext(CognitoContext)

  const currentSessionContext = useContext(CurrentSessionContext)
  const currentSessionIndex = useContext(CurrentSessionIndex)
  const [questionsAndResponses, setQuestionsAndResponses] = useState([])

  const callBackend = async (userQuestion, codeParam) => {
    const newQuestionAndResponse: QuestionAndResponse = {
      userQuestion: userQuestion,
      chunks: [],
      isStreamingComplete: false,
      completeStreamIndex: -1,
    }

    setQuestionsAndResponses((prevQuestionAndResponses) => {
      const updatedArr = [...prevQuestionAndResponses, newQuestionAndResponse]
      return updatedArr
    })

    // Connect to the WebSocket
    const websocket = new WebSocket(
      `wss://20ut71qt4a.execute-api.us-east-1.amazonaws.com/${process.env.USER_BRANCH || 'dev'}`
    )

    // Connection established
    websocket.addEventListener('open', (event) => {
      console.log('WebSocket connected:', event)

      // Send a 'process' message to the server
      const payload = {
        code: codeParam,
        taskType: 'custom',
        userQuestion: userQuestion,
        authContext: {
          identityId: cognitoContext.identityId,
          accessKeyId: cognitoContext.accessKeyId,
          secretAccessKey: cognitoContext.secretAccessKey,
          sessionToken: cognitoContext.sessionToken,
          // TODO: after adding new cognito creds context, splat them here to get all the data (i.e. sessionToken, accessToken, etc.)
          userId: user.userId,
        },
      }

      // credentials: new AWS.Credentials(authContext.accessKeyId, authContext.secretAccessKey, authContext.sessionToken),

      const message = {
        action: 'process',
        data: payload,
      }

      console.log('Sending payload: ', payload)
      websocket.send(JSON.stringify(message))
    })

    websocket.addEventListener('message', (event) => {
      // Parse the JSON response
      try {
        const jsonResponse = JSON.parse(event.data)

        // Check if the status is 'success'
        if (jsonResponse.status === 'success') {
          const chunk = JSON.parse(jsonResponse.data)

          // Update the questionsAndResponses state
          setQuestionsAndResponses((prevQuestionAndResponses) => {
            let currentQuestionAndResponseIndex =
              prevQuestionAndResponses.length - 1
            const currentQuestionAndResponse =
              prevQuestionAndResponses[currentQuestionAndResponseIndex]

            // Check if the chunk is already in the array
            const chunkExists = currentQuestionAndResponse.chunks.some(
              (existingChunk) => existingChunk.index === chunk.index
            )

            // If the chunk already exists, return the previous state without changes
            if (chunkExists) {
              return prevQuestionAndResponses
            }

            // Otherwise, add the new chunk to the array and sort them
            const updatedChunks = [
              ...currentQuestionAndResponse.chunks,
              chunk,
            ].sort((a, b) => a.index - b.index)
            const updatedItem = {
              ...currentQuestionAndResponse,
              chunks: updatedChunks,
            }

            if (chunk.done) {
              updatedItem.isStreamingComplete = true
              updatedItem.completeStreamIndex = chunk.index
            }

            const updatedArr = [
              ...prevQuestionAndResponses.slice(
                0,
                currentQuestionAndResponseIndex
              ),
              updatedItem,
              ...prevQuestionAndResponses.slice(
                currentQuestionAndResponseIndex + 1
              ),
            ]

            return updatedArr
          })
        } else {
          console.error(event.data)
          //alert('We are at capacity. Please try again later. ')
        }
      } catch (error) {
        console.error(error)
      }
      // console.log(event.data)
    })

    // Connection closed
    websocket.addEventListener('close', (event) => {
      console.log('WebSocket closed:', event)
    })

    // Connection error
    websocket.addEventListener('error', (event) => {
      console.error('WebSocket error:', event)
    })
  }

  useEffect(() => {
    if (currentSessionContext.length) {
      setQuestionsAndResponses(
        currentSessionContext[currentSessionIndex].questionAndResponses
      )
    }
  }, [currentSessionContext, currentSessionIndex])

  return (
    <CallBackendContext.Provider value={callBackend}>
      <QuestionsAndResponses.Provider value={questionsAndResponses}>
        <SetQuestionsAndResponsesContext.Provider
          value={setQuestionsAndResponses}
        >
          <EditorContext />
        </SetQuestionsAndResponsesContext.Provider>
      </QuestionsAndResponses.Provider>
    </CallBackendContext.Provider>
  )
}
export default APIContext
