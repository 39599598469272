import React, { useContext, useEffect, useState } from 'react'
import { defaultEditorValue } from '../../constDefaultValues'

import Main from '../home/Main'
import { CurrentSessionContext, CurrentSessionIndex } from './SessionContext'

export interface EditorCode {
  original: string
  modified: string
  ai: string
  language: string
}

export enum EditorSelector {
  ORIGINAL,
  MODIFIED,
  BOTH,
  AI,
}

const emptyEditor = {
  original: '',
  modified: '',
  ai: '',
  language: '',
}

export const EditorValueContext = React.createContext(emptyEditor)
export const EditorSetterContext = React.createContext(null)

const EditorContext = () => {
  const currentSessionContext = useContext(CurrentSessionContext)
  const currentSessionIndex = useContext(CurrentSessionIndex)
  const [editorValue, setEditorValue] = useState(emptyEditor)

  useEffect(() => {
    if (currentSessionContext.length) {
      setEditorValue(currentSessionContext[currentSessionIndex].editorValue)
    }
  }, [currentSessionContext, currentSessionIndex])

  function handleEditorChange(value, curEditor, language) {
    const editorchoice = curEditor
      ? curEditor
      : editorValue.modified
      ? EditorSelector.MODIFIED
      : EditorSelector.ORIGINAL
    switch (editorchoice) {
      case EditorSelector.ORIGINAL:
        setEditorValue((prevEditorValue) => ({
          ...prevEditorValue,
          original: value,
          language: language ? language : prevEditorValue.language,
        }))
        break
      case EditorSelector.MODIFIED:
        setEditorValue((prevEditorValue) => ({
          ...prevEditorValue,
          modified: value,
          language: language ? language : prevEditorValue.language,
        }))
        break
      case EditorSelector.AI:
        setEditorValue((prevEditorValue) => ({
          ...prevEditorValue,
          ai: value,
          language: language ? language : prevEditorValue.language,
        }))
        break
      case EditorSelector.BOTH:
        setEditorValue((prevEditorValue) => ({
          ...prevEditorValue,
          original: value,
          modified: value,
          ai: value,
          language: language ? language : prevEditorValue.language,
        }))
    }
  }

  return (
    <EditorSetterContext.Provider value={handleEditorChange}>
      <EditorValueContext.Provider value={editorValue}>
        <Main />
      </EditorValueContext.Provider>
    </EditorSetterContext.Provider>
  )
}
export default EditorContext
