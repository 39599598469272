import React, { useState } from 'react'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism'

const markdown = `

1. First item
2. Second item
3. Third item
4. Fourth item

\`Java\`

\`\`\`Java
/**
 * Test Call Backend Function for various input values
 **/
@Test
public void testCallBackend() {
    // Test 1: When props.message is null
    assertNull(callBackend(null).getCode());

    // Test 2: When code is null
    String invalidCode = null;
    Input input = new Input(invalidCode, "unitTest", "Java");
    Response response = callBackend(input);
    assertNull(response.getCode());

    // Test 3: When taskType is perf
    String validCode = "System.out.println(\"Hello World\")";
    input = new Input(validCode, "perf", "Java");
    response = callBackend(input);
    assertNotNull(response.getCode());
    assertFalse(isAPILoading());
}
\`\`\`
VM2896 react_devtools_backend.js:2709 \`Java\`\`\`\`Java/** * Test Call Backend Function for various input values **/@Testpublic void testCallBackend() {    // Test 1: When props.message is null    assertNull(callBackend(null).getCode());    // Test 2: When code is null    String invalidCode = null;    Input input = new Input(invalidCode, "unitTest", "Java");    Response response = callBackend(input);    assertNull(response.getCode());    // Test 3: When taskType is perf    String validCode = "System.out.println(\"Hello World\")";    input = new Input(validCode, "perf", "Java");    response = callBackend(input);    assertNotNull(response.getCode());    assertFalse(isAPILoading());}\`\`\`


`

const Markdown = (props) => {
  const [isCopied, setIsCopied] = useState(false)

  return (
    <div className="mx-auto grid w-full grid-cols-1 gap-1 lg:grid-cols-8">
      <div className="flex-col lg:col-span-8">
        <div className="rounded-xl">
          <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            children={props.message}
            components={{
              code({ node, inline, className, children, ...props }) {
                const match = /language-(\w+)/.exec(className || '') || [
                  'language-cmd',
                  'cmd',
                ]
                const [copied, setCopied] = useState(false)

                const handleCopy = () => {
                  navigator.clipboard
                    .writeText(String(children).replace(/\n$/, ''))
                    .then(() => {
                      setIsCopied(true)
                      setTimeout(() => {
                        setIsCopied(false)
                      }, 5000) // Reset "copied" state after 5 seconds
                    })
                }

                return !inline && match ? (
                  <div className="code-block">
                    <div className="relative flex items-center rounded-t-md bg-gray-800 px-4 py-2 font-sans text-[11px] text-gray-200">
                      <span>{match[1]}</span>
                      <button
                        className="ml-auto flex gap-2"
                        onClick={handleCopy}
                      >
                        <svg
                          stroke="currentColor"
                          fill="none"
                          strokeWidth="1"
                          viewBox="0 0 24 24"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="h-4 w-4"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path>
                          <rect
                            x="8"
                            y="2"
                            width="8"
                            height="4"
                            rx="1"
                            ry="1"
                          ></rect>
                        </svg>
                        {isCopied ? 'Copied!' : 'Copy code'}
                      </button>
                    </div>

                    <SyntaxHighlighter
                      children={String(children).replace(/\n$/, '')}
                      language={match[1]}
                      PreTag="div"
                      style={atomDark}
                      customStyle={{
                        marginTop: '0',
                        borderTopLeftRadius: '0',
                        borderTopRightRadius: '0',
                      }}
                      {...props}
                    />
                  </div>
                ) : (
                  <code className={className} {...props}>
                    {children}
                  </code>
                )
              },
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default Markdown
