import React from 'react'

function UserAgreement() {
  return (
    <div className="bg-gray-50">
      {/* <HeaderClear showLogo={true} showRun={false} /> */}
      <div className="flex flex-col bg-white">
        <div className="mx-auto max-w-4xl px-4 py-8">
          <h1 className="mb-4 text-2xl font-bold">
            BetterCodeAI User Agreement
          </h1>

          <div className="p-4">
            <p className="text-base">
              This User Agreement ("Agreement") is a legal agreement between you
              ("User" or "you") and BetterCodeAI LLC. ("Company," "we," "us," or
              "our") governing your use of our website, bettercodeai.com (the
              "Website"), and any content, features, or services offered by us
              through the Website.
            </p>
            <p className="text-base">
              By accessing or using the Website, you agree to be bound by the
              terms and conditions of this Agreement. If you do not agree to the
              terms and conditions of this Agreement, you may not use the
              Website.
            </p>
            <h2 className="mt-6 text-2xl font-bold">(1) Use of the Website</h2>
            <p className="text-base">
              <strong>1.1. Restrictions.</strong> You agree to use the Website
              only for lawful purposes and in accordance with this Agreement.
              You agree not to:
            </p>
            <ul className="ml-6 list-disc">
              <li className="mt-2">
                {' '}
                (a) use the Website in any way that violates any applicable
                federal, state, local, or international law or regulation;
              </li>
              <li className="mt-2">
                {' '}
                (b) use the Website for the purpose of exploiting, harming, or
                attempting to exploit or harm minors in any way by exposing them
                to inappropriate content, asking for personally identifiable
                information, or otherwise;
              </li>
              <li className="mt-2">
                {' '}
                (c) engage in any other conduct that restricts or inhibits
                anyone's use or enjoyment of the Website, or which, as
                determined by us, may harm us or users of the Website or expose
                them to liability;
              </li>
              <li className="mt-2">
                {' '}
                (d) with exception to search engine indexing, use any robot,
                spider, or other automatic device, process, or means to access
                the Website for any purpose, including monitoring or copying any
                of the material on the Website;
              </li>
              <li className="mt-2">
                {' '}
                (e) use any manual process to monitor or copy any of the
                material on the Website, or for any other purpose not expressly
                authorized in this Agreement;
              </li>
              <li className="mt-2">
                {' '}
                (f) use any device, software, or routine that interferes with
                the proper working of the Website;
              </li>
              <li className="mt-2">
                {' '}
                (g) introduce any viruses, Trojan horses, worms, logic bombs, or
                other material that is malicious or technologically harmful;
              </li>
              <li className="mt-2">
                {' '}
                (h) attempt to gain unauthorized access to, interfere with,
                damage, or disrupt any parts of the Website, the server on which
                the Website is stored, or any server, computer, or database
                connected to the Website;
              </li>
              <li className="mt-2">
                {' '}
                (i) attack the Website via a denial-of-service attack or a
                distributed denial-of-service attack; or
              </li>
              <li className="mt-2">
                {' '}
                (j) otherwise attempt to interfere with the proper working of
                the Website.
              </li>
            </ul>
            <p className="text-base">
              <strong>1.2. Changes.</strong> We may, in our sole discretion,
              change or discontinue any aspect, feature, or service of the
              Website at any time without notice to you.
            </p>
            <h2 className="mt-6 text-2xl font-bold">(2) User Content</h2>
            <p className="text-base">
              <strong>2.1. Responsibility.</strong> You are solely responsible
              for the content that you submit or make available on the Website,
              including, without limitation, any text, photographs, videos, or
              other materials (collectively, "User Content").
            </p>

            <p className="text-base">
              <strong>2.2. Ownership.</strong> You retain all ownership rights
              in your User Content. By submitting or making available any User
              Content on the Website, you grant us a non-exclusive,
              transferable, sublicensable, royalty-free, worldwide license to
              use, copy, modify, create derivative works based on, distribute,
              publicly display, publicly perform, and otherwise exploit in any
              manner such User Content in all formats and distribution channels
              now known or hereafter devised (including in connection with the
              Website and our business and on third-party sites and services),
              without further notice to or consent from you, and without the
              requirement of payment to you or any other person or entity.
            </p>
            <p className="text-base">
              <strong>2.3. Representations and Warranties.</strong> By
              submitting or making available any User Content on the Website,
              you represent and warrant that:
            </p>
            <ul className="ml-6 list-disc">
              <li className="mt-2">
                {' '}
                (a) you are the creator and owner of the User Content or have
                the necessary licenses, rights, consents, and permissions to
                authorize us and our users to use and distribute your User
                Content as necessary to exercise the licenses granted by you in
                this Agreement;
              </li>
              <li className="mt-2">
                {' '}
                (b) your User Content does not and will not infringe,
                misappropriate, or violate any third-party rights, including,
                without limitation, privacy rights, publicity rights,
                copyrights, trademark and/or other intellectual property rights,
                or result in the violation of any applicable law or regulation;
                and
              </li>
              <li className="mt-2">
                {' '}
                (c) your User Content does not contain any viruses, adware,
                spyware, worms, or other harmful or malicious code.
              </li>
            </ul>
            <p className="text-base">
              <strong>2.4. Removal.</strong> We reserve the right to remove any
              User Content from the Website at any time and for any reason,
              including if, in our sole discretion, we determine that such User
              Content violates this Agreement or is otherwise inappropriate.
            </p>
            <h2 className="mt-6 text-2xl font-bold">
              (3) Intellectual Property
            </h2>
            <p className="text-base">
              <strong>3.1. Ownership.</strong> The Website and its entire
              contents, features, and functionality (including but not limited
              to all information, software, text, displays, images, video, and
              audio, and the design, selection, and arrangement thereof), are
              owned by the Company, its licensors, or other providers of such
              material and are protected by United States and international
              copyright, trademark, patent, trade secret, and other intellectual
              property or proprietary rights laws.
            </p>
            <p className="text-base">
              <strong>3.2. Restrictions.</strong> You may not copy, reproduce,
              distribute, modify, create derivative works of, publicly display,
              publicly perform, republish, download, store, or transmit any of
              the material on our Website, except as follows:
            </p>
            <ul className="ml-6 list-disc">
              <li className="mt-2">
                {' '}
                (a) Your computer may temporarily store copies of such materials
                in RAM incidental to your accessing and viewing those materials.
              </li>
              <li className="mt-2">
                {' '}
                (b) You may store files that are automatically cached by your
                Web browser for display enhancement purposes.
              </li>
              <li className="mt-2">
                {' '}
                (c) You may print or download one copy of a reasonable number of
                pages of the Website for your own personal, non-commercial use
                and not for further reproduction, publication, or distribution.
              </li>
              <li className="mt-2">
                {' '}
                (d) If we provide desktop, mobile, or other applications for
                download, you may download a single copy to your computer or
                mobile device solely for your own personal, non-commercial use,
                and not for further reproduction, publication, or distribution.
              </li>
            </ul>
            <p className="text-base">
              <strong>3.3. Trademarks.</strong> The Company name, the terms
              BetterCodeAI, and all related names, logos, product and service
              names, designs, and slogans are trademarks of the Company or its
              affiliates or licensors. You may not use such marks without the
              prior written permission of the Company. All other names, logos,
              product and service names, designs, and slogans on this Website
              are the trademarks of their respective owners.
            </p>
            <h2 className="mt-6 text-2xl font-bold">
              (4) Disclaimer of Warranties
            </h2>
            <p className="text-base">
              THE WEBSITE IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS,
              WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED
            </p>
            <h2 className="mt-6 text-2xl font-bold">
              (5) Limitation of Liability
            </h2>
            <p className="text-base">
              IN NO EVENT WILL THE COMPANY, ITS AFFILIATES, OR THEIR LICENSORS,
              SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE
              LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING
              OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE
              WEBSITE, ANY WEBSITES LINKED TO IT, ANY CONTENT ON THE WEBSITE OR
              SUCH OTHER WEBSITES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL,
              INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT
              LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL
              DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR
              ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA,
              AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF
              CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE.
            </p>
            <h2 className="mt-6 text-2xl font-bold">(6) Indemnification</h2>
            <p className="text-base">
              You agree to indemnify, defend, and hold harmless the Company, its
              affiliates, licensors, and service providers, and its and their
              respective officers, directors, employees, contractors, agents,
              licensors, suppliers, successors, and assigns from and against any
              claims, liabilities, damages, judgments, awards, losses, costs,
              expenses, or fees (including reasonable attorneys' fees) arising
              out of or relating to your violation of this Agreement or your use
              of the Website, including, without limitation, any User Content
              submitted by you, any use of the Website's content, services, and
              products other than as expressly authorized in this Agreement, or
              your use of any information obtained from the Website.
            </p>
            <h2 className="mt-6 text-2xl font-bold">(7) Termination</h2>
            <p className="text-base">
              We may terminate your access to and use of the Website at any
              time, for any reason, and without notice to you.
            </p>
            <h2 className="mt-6 text-2xl font-bold">
              (8) Governing Law and Jurisdiction
            </h2>
            <p className="text-base">
              This Agreement and any dispute or claim arising out of or in
              connection with it or its subject matter or formation (including
              non-contractual disputes or claims) shall be governed by and
              construed in accordance with the laws of the state of Washington,
              without giving effect to any choice or conflict of law provision
              or rule.<br></br> Any legal suit, action, or proceeding arising
              out of, or related to, this Agreement or the Website shall be
              instituted exclusively in the federal courts of the United States
              or the courts of the state of Washington. You waive any and all
              objections to the exercise of jurisdiction over you by such courts
              and to venue in such courts.
            </p>

            <h2 className="mt-6 text-2xl font-bold">(9) Miscellaneous</h2>
            <p className="text-base">
              <strong>9.1. Entire Agreement.</strong> This Agreement constitutes
              the entire agreement between you and the Company regarding the use
              of the Website, superseding any prior agreements between you and
              the Company relating to your use of the Website.
            </p>
            <p className="text-base">
              <strong>9.2. Waiver and Severability.</strong> Our failure to
              exercise or enforce any right or provision of this Agreement shall
              not constitute a waiver of such right or provision. If any
              provision of this Agreement is held by a court or other tribunal
              of competent jurisdiction to be invalid, illegal, or unenforceable
              for any reason, such provision shall be eliminated or limited to
              the minimum extent such that the remaining provisions of the
              Agreement will continue in full force and effect.
            </p>
            <p className="text-base">
              <strong>9.3. Assignment.</strong> This Agreement and your rights
              and obligations hereunder may not be assigned, transferred, or
              sublicensed by you without our prior written consent.
            </p>
            <p className="text-base">
              <strong>9.4. Contact.</strong> If you have any questions about
              this Agreement, please contact us at{' '}
              <a
                href="mailto:legal@bettercodeai.com"
                className="text-blue-600 hover:underline"
              >
                legal@bettercodeai.com
              </a>
              .
            </p>
            <p className="text-base">
              By using the Website, you acknowledge that you have read,
              understood, and agree to be bound by this Agreement.
            </p>
            <p className="text-base">Last updated: 2023-03-08</p>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  )
}

export default UserAgreement
