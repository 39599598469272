import { calcAPIResponse } from '../editor/EditorUtils'

function trimStep(step) {
  return step.replace(/^[1-9][0-9]*\.?\s*|-+\s*/, '')
}

export const createInitialQuestion = (questionAndResponses) => {
  const buffer = calcAPIResponse(
    questionAndResponses[questionAndResponses.length - 1].chunks
  )
  const regex = /^To make the code better:/
  const lines = buffer.split('\n')
  const startIndex = lines.findIndex((line) => regex.test(line))
  const stepRegex = /^.*$/
  const steps = []
  if (startIndex >= 0) {
    for (let i = startIndex + 1; i < lines.length; i++) {
      //   if (lines[i].trim() === '') break
      if (stepRegex.test(lines[i])) {
        steps.push(trimStep(lines[i]))
      }
    }
  }

  return steps
}
