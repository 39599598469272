import React from 'react'
import { Link } from 'react-router-dom'

const Error404 = () => {
  return (
    <div className="h-96 bg-gray-100">
      <div className="flex h-full w-full items-center justify-center px-4 py-5 sm:p-6">
        <div className="w-full max-w-sm rounded-xl bg-white shadow-lg">
          <div className="px-4 py-5 sm:p-6">
            <div className="text-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="mx-auto h-16 w-16 text-gray-900"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M10.05 4.575a1.575 1.575 0 10-3.15 0v3m3.15-3v-1.5a1.575 1.575 0 013.15 0v1.5m-3.15 0l.075 5.925m3.075.75V4.575m0 0a1.575 1.575 0 013.15 0V15M6.9 7.575a1.575 1.575 0 10-3.15 0v8.175a6.75 6.75 0 006.75 6.75h2.018a5.25 5.25 0 003.712-1.538l1.732-1.732a5.25 5.25 0 001.538-3.712l.003-2.024a.668.668 0 01.198-.471 1.575 1.575 0 10-2.228-2.228 3.818 3.818 0 00-1.12 2.687M6.9 7.575V12m6.27 4.318A4.49 4.49 0 0116.35 15m.002 0h-.002"
                />
              </svg>

              <p className="mt-5 text-xl font-bold text-gray-900">
                Sorry, we couldn't find that page.
              </p>
              <p className="mt-3 text-sm font-medium text-gray-500">
                Please try retyping the address or head back to our home page
              </p>
              <div className="mt-8">
                <Link
                  to="/"
                  className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-100 px-6 py-3 text-sm font-semibold leading-5 text-indigo-600 transition-all duration-200 hover:bg-indigo-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                >
                  Go Home
                </Link>
                {/* <button
                  type="button"
                  className="inline-flex items-center justify-center px-6 py-3 text-sm font-semibold leading-5 text-indigo-600 transition-all duration-200 bg-indigo-100 border border-transparent rounded-md hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600 hover:bg-indigo-600"
                >
                  Go Home
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Error404
