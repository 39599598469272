import React, { useRef, useEffect } from 'react'

const DynamicTextarea = (props) => {
  const textareaRef = useRef(null)

  const resizeTextarea = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto'
      textareaRef.current.style.height = textareaRef.current.scrollHeight + 'px'
    }
  }

  useEffect(() => {
    resizeTextarea()
  }, [props.value])

  return (
    <textarea
      {...props}
      ref={textareaRef}
      style={{
        maxHeight: '200px',
        overflowY: 'hidden',
      }}
      className="m-0 w-full resize-none border-0 bg-transparent p-0 pr-7 pl-2 focus:ring-0 focus-visible:ring-0 dark:bg-transparent md:pl-0"
      placeholder={props.placeholder}
      rows={1}
      data-id="root"
      tabIndex={0}
      onInput={resizeTextarea}
      disabled={props.disabled}
    />
  )
}

export default DynamicTextarea
