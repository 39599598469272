export interface User {
  userId: string
  cognitoIdentityId: string
  cognitoAccessKeyId: string
  cognitoSecretAccessKey: string
  cognitoSessionToken: string
  username: string
  nameFirst: string
  nameLast: string
  name: string
  email: string
  emailVerified: boolean
  createdAt: string
  updatedAt: string
  signedIn: boolean
  isLoading: boolean
  subscriptionId: string
}

export const defaultUser: User = {
  userId: null,
  cognitoIdentityId: null,
  cognitoAccessKeyId: null,
  cognitoSecretAccessKey: null,
  cognitoSessionToken: null,
  username: '',
  nameFirst: '',
  nameLast: '',
  name: '',
  email: '',
  emailVerified: false,
  createdAt: '',
  updatedAt: '',
  signedIn: false,
  isLoading: true,
  subscriptionId: null,
}
