/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "nodeapi",
            "endpoint": "https://md2rst14z0.execute-api.us-east-1.amazonaws.com/staging",
            "region": "us-east-1"
        },
        {
            "name": "stripeWebhooks",
            "endpoint": "https://5yqgubyihd.execute-api.us-east-1.amazonaws.com/staging",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:1b121f08-7d0e-48d9-8fe5-5f32b171c14a",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_i33nLYV3P",
    "aws_user_pools_web_client_id": "4uqgdo381aek0olv6v8dj5t1ru",
    "oauth": {
        "domain": "bettercodeai-staging.auth.us-east-1.amazoncognito.com",
        "scope": [
            "email",
            "openid",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/,https://bettercodeai.com/",
        "redirectSignOut": "http://localhost:3000/,https://bettercodeai.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "opentelemetrygpt-20230313081620-hostingbucket-staging",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d2824hlz2cduzn.cloudfront.net",
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "contextStore-staging",
            "region": "us-east-1"
        },
        {
            "tableName": "subscriptions-staging",
            "region": "us-east-1"
        },
        {
            "tableName": "usage-staging",
            "region": "us-east-1"
        },
        {
            "tableName": "user-staging",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
