import React, { useContext, useEffect, useRef, useState } from 'react'
import { ThreeDots } from 'react-loader-spinner'
import { CurrentSessionIndex } from '../contexts/SessionContext'
import Markdown from './Markdown'
import RecommendationButton from './RecommendationButton'

const ChatItemAI = ({
  buffer,
  isComplete,
  isInitial,
  calcdRecommendations,
}) => {
  const [recommendations, setRecommendations] = useState([])
  const currentSessionIndex = useContext(CurrentSessionIndex)

  //const [introMessage, setIntroMessage] = useState('') // for inital message
  //const message = isInitial ? introMessage : buffer // final message we will output here

  //console.log(message)
  //console.log(buffer)

  // useEffect(() => {
  //   function runOnInitial() {
  //     const initialMessageLength = buffer.length
  //     let index = 0
  //     const tmp = setInterval(() => {
  //       if (index <= initialMessageLength) {
  //         setIntroMessage(buffer.slice(0, index))
  //         index += 1
  //       } else {
  //         clearInterval(tmp)
  //       }
  //     }, 1)
  //   }

  //   if (isInitial) {
  //     runOnInitial()
  //   }
  // }, [isInitial, buffer])

  function trimStep(step) {
    return step.replace(/^[1-9][0-9]*\.?\s*|-+\s*/, '')
  }

  useEffect(() => {
    if (isInitial) {
      setRecommendations([...calcdRecommendations])
    } else if (isComplete) {
      const regex = /^To make the code better:/
      const lines = buffer.split('\n')
      const startIndex = lines.findIndex((line) => regex.test(line))
      const stepRegex = /^.*$/
      const steps = []
      if (startIndex >= 0) {
        let countRecs = 0
        for (let i = startIndex + 1; i < lines.length; i++) {
          if (countRecs < 3) {
            if (stepRegex.test(lines[i])) {
              steps.push(trimStep(lines[i]))
              countRecs++
            }
          }
        }
      }

      if (steps.length) {
        setRecommendations(steps)
      } else {
        setRecommendations([...calcdRecommendations])
      }
    }
  }, [isComplete, isInitial, buffer])

  return (
    <div
      className="group w-full border-b border-black/10 bg-gray-50 text-gray-800 dark:border-gray-900/50 dark:bg-[#444654] dark:text-gray-100
    "
    >
      <div className="m-auto flex gap-4 p-4 text-base md:max-w-2xl md:gap-6 md:py-6 lg:max-w-xl lg:px-0 xl:max-w-3xl">
        <div className="relative flex w-[30px] flex-col items-end">
          <div className="relative flex">
            <span className="box-sizing: border-box; display: inline-block; overflow: hidden; background-image: none; opacity: 1; border: 0px; margin: 0px; padding: 0px; position: relative; max-width: 100%;">
              <span className="box-sizing: border-box; display: block; background-image: none; opacity: 1; border: 0px; margin: 0px; padding: 0px; max-width: 100%;"></span>
            </span>
          </div>
        </div>
        <div className="relative flex w-[calc(100%-50px)] flex-col gap-1 md:gap-3 lg:w-[calc(100%-115px)]">
          <div className="flex flex-grow flex-col gap-3">
            <div className="flex min-h-[20px] flex-col items-start gap-4 whitespace-pre-wrap">
              {buffer.length ? (
                <Markdown message={buffer} />
              ) : (
                <div className="flex">
                  {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="green"
                  className="mr-6 h-6 w-6"
                >
                  <path d="M16.5 7.5h-9v9h9v-9z" />
                  <path
                    fillRule="evenodd"
                    d="M8.25 2.25A.75.75 0 019 3v.75h2.25V3a.75.75 0 011.5 0v.75H15V3a.75.75 0 011.5 0v.75h.75a3 3 0 013 3v.75H21A.75.75 0 0121 9h-.75v2.25H21a.75.75 0 010 1.5h-.75V15H21a.75.75 0 010 1.5h-.75v.75a3 3 0 01-3 3h-.75V21a.75.75 0 01-1.5 0v-.75h-2.25V21a.75.75 0 01-1.5 0v-.75H9V21a.75.75 0 01-1.5 0v-.75h-.75a3 3 0 01-3-3v-.75H3A.75.75 0 013 15h.75v-2.25H3a.75.75 0 010-1.5h.75V9H3a.75.75 0 010-1.5h.75v-.75a3 3 0 013-3h.75V3a.75.75 0 01.75-.75zM6 6.75A.75.75 0 016.75 6h10.5a.75.75 0 01.75.75v10.5a.75.75 0 01-.75.75H6.75a.75.75 0 01-.75-.75V6.75z"
                    clipRule="evenodd"
                  />
                </svg> */}

                  <ThreeDots
                    height="40"
                    width="40"
                    radius="5"
                    color="#4fa94d"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    visible={true}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="flex justify-between lg:block">
            {currentSessionIndex !== 0 ? (
              <></>
            ) : isComplete ? (
              <div className="flex items-center justify-between rounded-md bg-gray-100 p-4 text-sm text-gray-700 dark:bg-gray-700 dark:text-gray-300">
                <div className="mr-4">Here are some suggestions:</div>
                {recommendations.map((rec, index) => (
                  <RecommendationButton rec={rec} index={index} key={index} />
                ))}
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChatItemAI
