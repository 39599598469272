import React, { useContext } from 'react'
import { CallBackendContext } from '../contexts/APIContext'
import { EditorValueContext } from '../contexts/EditorContext'
import {
  CurrentSessionContext,
  CurrentSessionIndex,
} from '../contexts/SessionContext'

const RecommendationButton = ({ rec, index }) => {
  const callBackend = useContext(CallBackendContext)
  const editorValue = useContext(EditorValueContext)

  const handleSubmit = (e) => {
    const valToSend = editorValue.modified
      ? editorValue.ai
      : editorValue.original
    callBackend(e.target.value, valToSend)
  }

  return (
    <button
      onClick={handleSubmit}
      key={index}
      value={rec}
      className="flex items-center gap-2 rounded-md p-2 hover:bg-gray-200 hover:text-gray-800 dark:text-gray-400 dark:hover:bg-gray-800 dark:hover:text-gray-200"
    >
      {rec}
    </button>
  )
}

export default RecommendationButton
