import awsConfig from '../../aws-exports'
import React, { useEffect, useState } from 'react'
import { Amplify, API, Auth, Hub } from 'aws-amplify'
import { defaultUser, User } from '../../Models/User'
import { defaultCognito, Cognito } from '../../Models/Cognito'
import { defaultSubscription, Subscription } from '../../Models/Subscription'
import App from '../../App'

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
)

// Assuming you have two redirect URIs, and the first is for localhost and second is for production
const [localRedirectSignIn, productionRedirectSignIn] =
  awsConfig.oauth.redirectSignIn.split(',')

const [localRedirectSignOut, productionRedirectSignOut] =
  awsConfig.oauth.redirectSignOut.split(',')

async function getCurrentUser() {
  let result = null
  try {
    result = await Auth.currentAuthenticatedUser()
  } catch (error) {
    // not logged in!
  }
  return result
}
function updateEndpoint(list, name, endpoint) {
  const item = list.find((obj) => obj.name === name)

  if (item) {
    item.endpoint = endpoint
  } else {
    console.log(`Item with name "${name}" not found.`)
  }
  return item
}

const updatedAwsConfig = {
  ...awsConfig,
  //aws_cloud_logic_custom: updateEndpoint(awsConfig.aws_cloud_logic_custom, "nodeapi", "https://api.bettercodeai.com"),
  oauth: {
    ...awsConfig.oauth,
    redirectSignIn: isLocalhost
      ? localRedirectSignIn
      : productionRedirectSignIn,
    redirectSignOut: isLocalhost
      ? localRedirectSignOut
      : productionRedirectSignOut,
  },
}

//console.log("updatedAwsConfig", updatedAwsConfig);
Amplify.configure(updatedAwsConfig)

export const AuthorizationContext = React.createContext(defaultUser)
export const CognitoContext = React.createContext(defaultCognito)
export const SubscriptionContext = React.createContext(defaultSubscription)

const AuthContext = () => {
  let [user, setUser] = useState<User>(defaultUser) // BetterCodeAI User Object
  let [cognito, setCognito] = useState<Cognito>(defaultCognito) // BetterCodeAI Cognito Object

  const [subscription, setSubscription] =
    useState<Subscription>(defaultSubscription) // BetterCodeAI Subscription Object

  /**
   * Make request to BetterCodeAI backend UserAPI and SubscriptionAPI
   */
  useEffect(() => {
    const getUserAndSubscription = async () => {
      let userBody = {}
      let myuser = defaultUser
      let mysub = defaultSubscription

      const currentUser = await getCurrentUser()

      try {
        // console.log('Is signed in?', currentUser ? true : false)
        if (currentUser) {
          myuser = await API.get('nodeapi', `/users/me`, {})
          console.log('GET /users/me', myuser)

          userBody = {
            email: currentUser.attributes.email,
            name: currentUser.attributes.name,
            emailVerified: currentUser.attributes.email_verified,
            username: currentUser.username,
          }

          if (!myuser || !myuser.userId) {
            myuser = await API.post('nodeapi', `/users/me`, { body: userBody })
            console.log('POST /users/me', myuser)
          }

          // Get subscription info
          if (myuser && myuser.subscriptionId) {
            mysub = await API.get(
              'nodeapi',
              `/subscriptions/${myuser.subscriptionId}`,
              {}
            )
            console.log(
              'Response',
              `GET /subscriptions/${myuser.subscriptionId}`,
              mysub
            )
          }
        }
      } catch (e) {
        console.error('Failed to get user and subscription', e)
      } finally {
        setSubscription((prevSub) => ({
          ...prevSub,
          ...mysub,
          isPaymentLoading: false,
          hasSubscription: mysub && mysub.subscriptionId ? true : false,
          // TODO: why are we stting userId to cognitoIdentityId? Shouldn't it be myuser.userId?
          userId: cognito.identityId,
        }))

        setUser((prevUser) => ({
          ...prevUser,
          ...myuser,
          ...userBody,
          isLoading: false,
        }))
      }
    }

    if (cognito.identityId) {
      console.log('cognito', cognito)
      getUserAndSubscription()
    }
  }, [cognito.identityId])

  /**
   * Listen to Auth Updates from Amazon and update unique user id state variable
   */
  useEffect(() => {
    const unsubscribe = Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
          Auth.currentCredentials().then((creds) => {
            setCognito((prevCognito) => ({
              ...prevCognito,
              identityId: creds.identityId,
              accessKeyId: creds.accessKeyId,
              secretAccessKey: creds.secretAccessKey,
              sessionToken: creds.sessionToken,
            }))
          })
          break
        case 'signOut':
          setCognito({
            ...defaultCognito,
          })
          break
      }
    })

    Auth.currentCredentials()
      .then((creds) => {
        setCognito((prevCognito) => ({
          ...prevCognito,
          identityId: creds.identityId,
          accessKeyId: creds.accessKeyId,
          secretAccessKey: creds.secretAccessKey,
          sessionToken: creds.sessionToken,
        }))
      })
      .catch(() => {
        console.log(
          'Not signed in. Redirect URL:' + updatedAwsConfig.oauth.redirectSignIn
        )
        setUser((prevUser) => ({
          ...prevUser,
          isLoading: false,
        }))
      })

    return unsubscribe
  }, [])

  return (
    <AuthorizationContext.Provider value={user}>
      <SubscriptionContext.Provider value={subscription}>
        <CognitoContext.Provider value={cognito}>
          <App />
        </CognitoContext.Provider>
      </SubscriptionContext.Provider>
    </AuthorizationContext.Provider>
  )
}
export default AuthContext
