function parseGPTresponse(markdownText) {
  const codeBlockPattern = /```(.*?)```/gs
  let codeBlocks = []
  let match

  while ((match = codeBlockPattern.exec(markdownText)) !== null) {
    codeBlocks.push(match[1])
  }

  return codeBlocks.join('\n\n=======\n\n')
}

export default parseGPTresponse
