import React, { useContext } from 'react'
import { QuestionsAndResponses } from '../contexts/APIContext'
import { EditorValueContext } from '../contexts/EditorContext'
import {
  CurrentSessionContext,
  CurrentSessionIndex,
  SetCurrentSessionContext,
} from '../contexts/SessionContext'
import { initialQuestionText, initialRecommendations } from './ChatScroll'

const VersionItem = ({ version, session, isHighlight, index }) => {
  const currentSessionIndex = useContext(CurrentSessionIndex)
  const setSessionContext = useContext(SetCurrentSessionContext)
  const currentSessionContext = useContext(CurrentSessionContext)
  const questionsAndResponses = useContext(QuestionsAndResponses)
  const editorValue = useContext(EditorValueContext)

  const handleClick = () => {
    // if at current, we need to store current state temporarily
    if (index === currentSessionIndex) {
      return
    }

    const text = currentSessionContext[currentSessionIndex].initialQuestion.text
      .length
      ? currentSessionContext[currentSessionIndex].initialQuestion.text
      : initialQuestionText

    const recs = currentSessionContext[currentSessionIndex].initialQuestion
      .recommendations.length
      ? currentSessionContext[currentSessionIndex].initialQuestion
          .recommendations
      : initialRecommendations
    // add current to session

    setSessionContext(
      questionsAndResponses,
      editorValue,
      currentSessionIndex,
      index,
      false,
      {
        text: text,
        recommendations: recs,
      }
    )
  }

  const AclassNames = isHighlight
    ? 'group relative flex cursor-pointer items-center gap-3 break-all rounded-md py-3 px-3 hover:bg-[#2A2B32] hover:pr-4 bg-gray-800'
    : 'group relative flex cursor-pointer items-center gap-3 break-all rounded-md py-3 px-3 hover:bg-[#2A2B32] hover:pr-4'

  const gradientClassNames = isHighlight
    ? 'absolute inset-y-0 right-0 z-10 w-8 from-gray-800'
    : 'absolute inset-y-0 right-0 z-10 w-8 bg-gradient-to-l from-gray-900 group-hover:from-[#2A2B32]'

  return (
    <a onClick={handleClick} className={AclassNames}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        className="h-5 w-5"
      >
        <path
          fillRule="evenodd"
          d="M4.25 2A2.25 2.25 0 002 4.25v11.5A2.25 2.25 0 004.25 18h11.5A2.25 2.25 0 0018 15.75V4.25A2.25 2.25 0 0015.75 2H4.25zm4.03 6.28a.75.75 0 00-1.06-1.06L4.97 9.47a.75.75 0 000 1.06l2.25 2.25a.75.75 0 001.06-1.06L6.56 10l1.72-1.72zm4.5-1.06a.75.75 0 10-1.06 1.06L13.44 10l-1.72 1.72a.75.75 0 101.06 1.06l2.25-2.25a.75.75 0 000-1.06l-2.25-2.25z"
          clipRule="evenodd"
        />
      </svg>

      <div className="relative max-h-5 flex-1 overflow-hidden text-ellipsis break-all">
        {version}
        <div className={gradientClassNames}></div>
        {/* <div className="absolute inset-y-0 right-0 z-10 w-8 bg-gradient-to-l from-gray-800 "></div> */}
      </div>
    </a>
  )
}

export default VersionItem
