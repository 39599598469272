import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'

import Editor from '@monaco-editor/react'
import 'react-tooltip/dist/react-tooltip.css'
import {
  calcAPIResponseFromMainArray,
  calcIsComplete1Param,
  getDifferences,
  getLanguageIdentifier,
  removeLanguageIdentifier,
} from './EditorUtils'

import parseGPTresponse from '../../parseGPTCodeResponse'
import {
  EditorSelector,
  EditorSetterContext,
  EditorValueContext,
} from '../contexts/EditorContext'
import { QuestionsAndResponses } from '../contexts/APIContext'
import {
  CurrentSessionContext,
  CurrentSessionIndex,
  SetCurrentSessionContext,
} from '../contexts/SessionContext'
import { initialQuestionText, initialRecommendations } from '../home/ChatScroll'

const BetterCode = () => {
  const setEditorValue = useContext(EditorSetterContext)
  const questionsAndResponses = useContext(QuestionsAndResponses)
  const setSessionContext = useContext(SetCurrentSessionContext)
  const currentSessionIndex = useContext(CurrentSessionIndex)
  const currentSessionContext = useContext(CurrentSessionContext)
  const editorValue = useContext(EditorValueContext)

  const [isCopied, setIsCopied] = useState(false)
  const [isApproved, setIsApproved] = useState(false)
  const [isDenied, setIsDenied] = useState(false)
  const editor = useRef(null)
  const monaco = useRef(null)
  const currentDecorations = useRef([])
  const programmaticChange = useRef(false)
  const sessionSwitch = useRef(-1)

  const apiResponse = calcAPIResponseFromMainArray(questionsAndResponses)
  const isComplete = calcIsComplete1Param(questionsAndResponses)
  const { modified, original, ai, language } = editorValue

  useEffect(() => {
    if (!monaco.current) {
      return
    }

    if (editorValue.modified.length && isComplete) {
      const code = parseGPTresponse(removeLanguageIdentifier(apiResponse))
      if (!code.length) {
        return
      }

      const { resultWithoutDiffs, diffTypeArr } = getDifferences(
        editorValue.original,
        code
      )

      const decorations = diffTypeArr
        .map((diffType, index) => {
          if (diffType === 'unchanged') return null

          const lineNumber = index + 1
          const className = diffType === 'added' ? 'added-diff' : 'removed-diff'
          const glyphClassName =
            diffType === 'added' ? 'glyph-added' : 'glyph-removed'

          return {
            range: new monaco.current.Range(lineNumber, 1, lineNumber, 1),
            options: {
              isWholeLine: true,
              className: className,
              marginClassName: glyphClassName,
            },
          }
        })
        .filter((decoration) => decoration !== null)

      // Clear previous decorations
      if (currentDecorations.current.length) {
        editor.current.deltaDecorations(currentDecorations.current, [])
      }

      // Add new decorations
      currentDecorations.current = editor.current.deltaDecorations(
        [],
        decorations
      )
    } else {
      if (currentDecorations.current.length) {
        editor.current.deltaDecorations(currentDecorations.current, [])
      }
    }
  }, [modified, original, isComplete, monaco.current])

  useEffect(() => {
    if (isComplete) {
      const code = parseGPTresponse(removeLanguageIdentifier(apiResponse))
      const { resultWithoutDiffs, diffTypeArr } = getDifferences(
        editorValue.original,
        code
      )

      if (editorValue.modified !== resultWithoutDiffs && code.length) {
        const tmpLanguage = getLanguageIdentifier(apiResponse)
        programmaticChange.current = true // Set programmaticChange to true before updating the value
        setEditorValue(resultWithoutDiffs, EditorSelector.MODIFIED, tmpLanguage)
      }
      if (editorValue.ai !== code && code.length) {
        setEditorValue(code, EditorSelector.AI)
      }
    }
  }, [isComplete])

  const approveChanges = () => {
    if (questionsAndResponses.length === 0) {
      return
    }

    const text = currentSessionContext[currentSessionIndex].initialQuestion.text
      .length
      ? currentSessionContext[currentSessionIndex].initialQuestion.text
      : initialQuestionText

    const recs = currentSessionContext[currentSessionIndex].initialQuestion
      .recommendations.length
      ? currentSessionContext[currentSessionIndex].initialQuestion
          .recommendations
      : initialRecommendations

    const newEditorValue = {
      original: editorValue.original,
      modified: editorValue.ai, // instead of diff, we are accepting the recommendations from AI
      ai: editorValue.ai,
      language: editorValue.language,
    }

    setSessionContext(questionsAndResponses, newEditorValue, -1, 0, true, {
      text: text,
      recommendations: recs,
    })

    setIsApproved(true)
    setTimeout(() => {
      setIsApproved(false)
    }, 5000) // Reset "copied" state after 5 seconds
  }

  const denyChanges = () => {
    setEditorValue('', EditorSelector.MODIFIED)

    setIsDenied(true)
    setTimeout(() => {
      setIsDenied(false)
    }, 5000)
  }

  useEffect(() => {
    sessionSwitch.current = currentSessionIndex
  }, [currentSessionIndex])

  const handleEditorChange = useCallback((value, event) => {
    if (programmaticChange.current) {
      programmaticChange.current = false
      return
    }

    if (currentSessionIndex !== sessionSwitch.current) {
      sessionSwitch.current = currentSessionIndex
      return
    }
    setEditorValue(value)
  }, [])

  // const editorDidMount = (editor, monaco) => {
  //   setEditor(editor)
  //   setMonaco(monaco)
  // }

  const editorDidMount = useCallback((editorInstance, monacoInstance) => {
    editor.current = editorInstance
    monaco.current = monacoInstance
  }, [])

  const handleCopy = () => {
    const valueToCopy = calcIsComplete1Param(questionsAndResponses)
      ? editorValue.modified
      : editorValue.original

    navigator.clipboard.writeText(valueToCopy).then(() => {
      setIsCopied(true)
      setTimeout(() => {
        setIsCopied
      }, 5000) // Reset "copied" state after 5 seconds
    })
  }

  useEffect(() => {
    // Set the read-only hint message
    if (monaco.current) {
      monaco.current.editor.setReadOnlyHint(editor, 'This editor is read-only.')
    }
  }, [monaco])

  return (
    <div className="mx-auto h-full rounded-xl">
      <div
        className="relative flex items-center bg-gray-800 px-4 py-3 font-sans text-xs text-gray-200"
        style={{ height: '45px' }}
      >
        {currentSessionIndex !== 0 ? (
          <button className="ml-auto flex gap-2" onClick={approveChanges}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="h-4 w-4"
            >
              <path d="M1 8.25a1.25 1.25 0 112.5 0v7.5a1.25 1.25 0 11-2.5 0v-7.5zM11 3V1.7c0-.268.14-.526.395-.607A2 2 0 0114 3c0 .995-.182 1.948-.514 2.826-.204.54.166 1.174.744 1.174h2.52c1.243 0 2.261 1.01 2.146 2.247a23.864 23.864 0 01-1.341 5.974C17.153 16.323 16.072 17 14.9 17h-3.192a3 3 0 01-1.341-.317l-2.734-1.366A3 3 0 006.292 15H5V8h.963c.685 0 1.258-.483 1.612-1.068a4.011 4.011 0 012.166-1.73c.432-.143.853-.386 1.011-.814.16-.432.248-.9.248-1.388z" />
            </svg>
            <b>{'This version was approved!'}</b>
          </button>
        ) : isComplete && modified.length ? (
          <>
            <button className="ml-auto flex gap-2" onClick={approveChanges}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="h-4 w-4"
              >
                <path d="M1 8.25a1.25 1.25 0 112.5 0v7.5a1.25 1.25 0 11-2.5 0v-7.5zM11 3V1.7c0-.268.14-.526.395-.607A2 2 0 0114 3c0 .995-.182 1.948-.514 2.826-.204.54.166 1.174.744 1.174h2.52c1.243 0 2.261 1.01 2.146 2.247a23.864 23.864 0 01-1.341 5.974C17.153 16.323 16.072 17 14.9 17h-3.192a3 3 0 01-1.341-.317l-2.734-1.366A3 3 0 006.292 15H5V8h.963c.685 0 1.258-.483 1.612-1.068a4.011 4.011 0 012.166-1.73c.432-.143.853-.386 1.011-.814.16-.432.248-.9.248-1.388z" />
              </svg>

              {isApproved
                ? 'Approved!'
                : 'Approve changes and create new version'}
            </button>
            <button className="ml-auto flex gap-2" onClick={denyChanges}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="h-4 w-4"
              >
                <path d="M18.905 12.75a1.25 1.25 0 01-2.5 0v-7.5a1.25 1.25 0 112.5 0v7.5zM8.905 17v1.3c0 .268-.14.526-.395.607A2 2 0 015.905 17c0-.995.182-1.948.514-2.826.204-.54-.166-1.174-.744-1.174h-2.52c-1.242 0-2.26-1.01-2.146-2.247.193-2.08.652-4.082 1.341-5.974C2.752 3.678 3.833 3 5.005 3h3.192a3 3 0 011.342.317l2.733 1.366A3 3 0 0013.613 5h1.292v7h-.963c-.684 0-1.258.482-1.612 1.068a4.012 4.012 0 01-2.165 1.73c-.433.143-.854.386-1.012.814-.16.432-.248.9-.248 1.388z" />
              </svg>

              {isDenied ? 'Removed!' : 'Remove changes and reset editor'}
            </button>
          </>
        ) : (
          <></>
        )}
        <button className="ml-auto flex gap-2" onClick={handleCopy}>
          <svg
            stroke="currentColor"
            fill="none"
            strokeWidth="2"
            viewBox="0 0 24 24"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="h-4 w-4"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path>
            <rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect>
          </svg>
          {isCopied ? 'Copied!' : 'Copy'}
        </button>
      </div>
      <div className="editor-wrapper">
        <Editor
          height="97vh"
          width={'45vw'}
          language={language.length ? language : 'javascript'}
          value={modified.length ? modified : original}
          theme="vs-dark"
          onMount={editorDidMount}
          onChange={handleEditorChange}
          options={{
            readOnly: currentSessionIndex !== 0 || modified.length > 0,
          }}
        />
        {modified.length && currentSessionIndex === 0 ? (
          <div className="readonly-message">Approve or remove changes</div>
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}
export default BetterCode
