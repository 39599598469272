import React, { useContext, useState } from 'react'
import {
  CallBackendContext,
  QuestionsAndResponses,
} from '../contexts/APIContext'
import { EditorValueContext } from '../contexts/EditorContext'
import {
  CurrentSessionContext,
  CurrentSessionIndex,
} from '../contexts/SessionContext'
import DynamicTextarea from './DynamicTextArea'

const SendMessage = () => {
  const callBackend = useContext(CallBackendContext)
  const questionsAndResponses = useContext(QuestionsAndResponses)
  const currentSessionIndex = useContext(CurrentSessionIndex)
  const currentSessionContext = useContext(CurrentSessionContext)

  const editorValue = useContext(EditorValueContext)

  const [textAreaValue, setTextAreaValue] = useState('')

  const handleTextAreaChange = (event) => {
    setTextAreaValue(event.target.value)
  }

  const handleRetry = (e) => {
    if (currentSessionIndex !== 0) {
      return
    }
    e.preventDefault()
    setTextAreaValue('') // Clear the textarea
    callBackend(
      questionsAndResponses[lastItem].userQuestion,
      editorValue.original
    )
  }

  const handleSubmit = (e) => {
    if (currentSessionIndex !== 0) {
      return
    }
    e.preventDefault()
    setTextAreaValue('') // Clear the textarea
    const valToSend = editorValue.modified
      ? editorValue.ai
      : editorValue.original
    callBackend(textAreaValue, valToSend)
  }

  const handleKeyDown = (event) => {
    if (currentSessionIndex !== 0) {
      return
    }
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault() // Prevents adding a newline to the textarea
      setTextAreaValue('') // Clear the textarea
      const valToSend = editorValue.modified
        ? editorValue.ai
        : editorValue.original
      callBackend(textAreaValue, valToSend)
    }
  }

  const lastItem =
    questionsAndResponses && questionsAndResponses.length
      ? questionsAndResponses.length - 1
      : -1

  return (
    <div className="md:bg-vert-light-gradient dark:md:bg-vert-dark-gradient absolute bottom-0 left-0 w-full border-t bg-white pt-2 dark:border-white/20 dark:bg-gray-800 md:border-t-0 md:border-transparent md:!bg-transparent md:dark:border-transparent">
      <form className="stretch mx-2 flex flex-row gap-3 last:mb-2 md:mx-4 md:last:mb-6 lg:mx-auto lg:max-w-2xl">
        <div className="relative flex h-full flex-1 md:flex-col">
          <div className="ml-1 flex justify-center gap-0 md:m-auto md:mb-2 md:w-full md:gap-2">
            {currentSessionIndex !== 0 ? (
              <div className=" pointer-events-auto relative inline-flex items-center rounded border border-white bg-gray-100 px-2 py-3 text-sm dark:border-gray-900/50 dark:bg-gray-700 dark:text-gray-300 md:border">
                <div className="flex w-full items-center justify-center gap-2">
                  {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="h-5 w-5"
                >
                  <path
                    fillRule="evenodd"
                    d="M15.312 11.424a5.5 5.5 0 01-9.201 2.466l-.312-.311h2.433a.75.75 0 000-1.5H3.989a.75.75 0 00-.75.75v4.242a.75.75 0 001.5 0v-2.43l.31.31a7 7 0 0011.712-3.138.75.75 0 00-1.449-.39zm1.23-3.723a.75.75 0 00.219-.53V2.929a.75.75 0 00-1.5 0V5.36l-.31-.31A7 7 0 003.239 8.188a.75.75 0 101.448.389A5.5 5.5 0 0113.89 6.11l.311.31h-2.432a.75.75 0 000 1.5h4.243a.75.75 0 00.53-.219z"
                    clipRule="evenodd"
                  />
                </svg> */}
                  This version was approved
                </div>
              </div>
            ) : lastItem > -1 ? (
              <button
                onClick={handleRetry}
                disabled={lastItem < 0}
                className=" pointer-events-auto relative inline-flex items-center rounded border border-white bg-gray-100 px-2 py-3 text-sm dark:border-gray-900/50 dark:bg-gray-700 dark:text-gray-300 md:border"
              >
                <div className="flex w-full items-center justify-center gap-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="h-5 w-5"
                  >
                    <path
                      fillRule="evenodd"
                      d="M15.312 11.424a5.5 5.5 0 01-9.201 2.466l-.312-.311h2.433a.75.75 0 000-1.5H3.989a.75.75 0 00-.75.75v4.242a.75.75 0 001.5 0v-2.43l.31.31a7 7 0 0011.712-3.138.75.75 0 00-1.449-.39zm1.23-3.723a.75.75 0 00.219-.53V2.929a.75.75 0 00-1.5 0V5.36l-.31-.31A7 7 0 003.239 8.188a.75.75 0 101.448.389A5.5 5.5 0 0113.89 6.11l.311.31h-2.432a.75.75 0 000 1.5h4.243a.75.75 0 00.53-.219z"
                      clipRule="evenodd"
                    />
                  </svg>
                  Regenerate Response
                </div>
              </button>
            ) : (
              <></>
            )}
          </div>
          <div className="relative flex w-full flex-grow flex-col rounded-md border border-black/10 bg-white py-2 shadow-[0_0_10px_rgba(0,0,0,0.10)] dark:border-gray-900/50 dark:bg-gray-700 dark:text-white dark:shadow-[0_0_15px_rgba(0,0,0,0.10)] md:py-3 md:pl-4">
            <DynamicTextarea
              onChange={handleTextAreaChange}
              onKeyDown={handleKeyDown}
              value={textAreaValue}
              disabled={currentSessionIndex !== 0}
              placeholder="Send a message..."
            />
            <button
              onClick={handleSubmit}
              disabled={!textAreaValue}
              className="absolute bottom-1.5 right-1 rounded-md p-1 text-gray-500 hover:bg-gray-100 disabled:opacity-40 disabled:hover:bg-transparent dark:hover:bg-gray-900 enabled:dark:hover:text-gray-400 dark:disabled:hover:bg-transparent md:bottom-2.5 md:right-2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="h-5 w-5"
              >
                <path d="M3.105 2.289a.75.75 0 00-.826.95l1.414 4.925A1.5 1.5 0 005.135 9.25h6.115a.75.75 0 010 1.5H5.135a1.5 1.5 0 00-1.442 1.086l-1.414 4.926a.75.75 0 00.826.95 28.896 28.896 0 0015.293-7.154.75.75 0 000-1.115A28.897 28.897 0 003.105 2.289z" />
              </svg>
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}
export default SendMessage
